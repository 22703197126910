import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/navigation';
import { UserSessionData } from 'lib/prisma/queries/user/get-user-session-data';
import { getLoginPageUrl } from 'lib/url-helper/auth';
import storage from 'lib/storage';
import { PAGE_TO_RETURN_TO_AFTER_AUTH_STORAGE_KEY } from 'lib/constants';

type UseSessionProps = {
  redirectToLogin?: boolean;
};

type UserSessionStatus = 'loading' | 'authenticated' | 'unauthenticated';

const useSessionDefaultProps = {
  redirectToLogin: true,
};

export function useSession(props: UseSessionProps = useSessionDefaultProps) {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<UserSessionStatus>('loading');
  const [session, setSession] = useState<UserSessionData>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/v1/auth/get-session')
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.authenticated ? 'authenticated' : 'unauthenticated');
        setSession(data.session);
        Sentry.setUser({
          userId: data.session.id,
          companyId: data.session.company_id,
        });
      })
      .catch((error) => {
        setError(error);
        setStatus('unauthenticated');
        Sentry.setUser(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (status === 'unauthenticated' && props.redirectToLogin) {
    storage.setItem(
      PAGE_TO_RETURN_TO_AFTER_AUTH_STORAGE_KEY,
      window.location.href,
    );
    router.push(getLoginPageUrl());
  }

  return {
    loading,
    status,
    session,
    error,
  };
}
