import 'reflect-metadata';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { NavigationDrawer } from 'components/organisms/NavigationDrawer';
import { NavBar } from 'components/molecules/NavBar';
import { LoadingScreen } from 'components/atoms/LoadingScreen';
import { useSession } from 'lib/hooks/session';
import { defaultConfig } from 'components/misc/Meta';
import { AccountType } from 'lib/types/user';
import { GlobalNotificationBar } from 'components/atoms/NotificationBar';
import { getDefaultRoute } from 'lib/url-helper/routes';
import 'tailwindcss/tailwind.css';
import 'flag-icons/css/flag-icons.min.css';
import '../styles/custom.css';
import { IntercomChat } from '../components/atoms/IntercomChat';

function PrivateOnlyWrapper({ children }) {
  const { loading, status, session } = useSession();
  const router = useRouter();

  if (status === 'loading') {
    return <LoadingScreen show={loading} />;
  }

  if (status === 'unauthenticated') {
    return null;
  }

  // TODO improve with a FORBIDDEN page or similar
  if (
    router.asPath.includes('/payroll') &&
    AccountType.PayrollAdmin !== session.account_type &&
    AccountType.SuperAdmin !== session.account_type
  ) {
    return null;
  }

  return (
    <>
      <NavigationDrawer />
      <div className="relative min-h-full w-full pl-36 pr-16">
        <div className="pt-10 mb-14">
          <NavBar />
        </div>
        {children}
      </div>
      <IntercomChat session={session} />
    </>
  );
}

function PublicOnlyWrapper({ children }) {
  const { loading, status, error } = useSession({ redirectToLogin: false });
  const router = useRouter();

  if (loading) {
    return <LoadingScreen show={loading} />;
  }

  // When we land on auth related pages and we are logged in we go to the default route
  if (status === 'authenticated') {
    router.push(getDefaultRoute());
    return null;
  }

  return children;
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const isPublicRoute = router.asPath.startsWith('/auth/');

  return (
    <>
      <div className="relative w-full min-h-screen bg-gray-100 overflow-x-hidden">
        <Head>
          <link
            rel="icon"
            type="image/png"
            sizes="16×16"
            href="/assets/favicons/16x16.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32×32"
            href="/assets/favicons/32x32.png"
          />
        </Head>
        <NextSeo {...defaultConfig} />
        {isPublicRoute ? (
          <PublicOnlyWrapper>
            <Component {...pageProps} />
          </PublicOnlyWrapper>
        ) : (
          <PrivateOnlyWrapper>
            <Component {...pageProps} />
          </PrivateOnlyWrapper>
        )}
      </div>
      <GlobalNotificationBar />
    </>
  );
}

export default MyApp;
